<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="90%">
    <el-tabs type="border-card" value="base">
      <el-tab-pane name="base" label="问题详细" :style="panelStyle">
        <el-row>
          <el-col :span="12">
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>基本信息</span>
                </div>
              </template>
              <table class="table-detail">
                <tr>
                  <th>检查类型</th>
                  <td colspan="3">{{ dataInfo.matterType }}</td>
                </tr>
                <tr>
                  <th>上报人</th>
                  <td>{{ dataInfo.createUserName }}</td>
                  <th>上报部门</th>
                  <td>{{ dataInfo.createDepartName }}</td>
                </tr>
                <tr>
                  <th>上报时间</th>
                  <td>{{ dataInfo.submitTime }}</td>
                  <th>上报状态</th>
                  <td class="text-bold">
                    <span v-if="dataInfo.matterStatus == 0" class="text-grey">未提交</span>
                    <span v-else-if="dataInfo.matterStatus == 1" class="text-blue">已提交</span>
                    <span v-else-if="dataInfo.matterStatus == 10" class="text-green">已采纳</span>
                    <span v-else-if="dataInfo.matterStatus == 9" class="text-red">无效</span>
                  </td>
                </tr>
              </table>
            </el-card>
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>隐患图片</span>
                </div>
              </template>
              <div>
                <el-col v-for="(item, index) in dataInfo.matterImgList" :key="index" :span="6">
                  <el-card class="padding0 margin-mini" style="line-height:0px;">
                    <el-image style="width: 100%; height: 80px" :src="item" :preview-src-list="dataInfo.matterImgList"> </el-image>
                  </el-card>
                </el-col>
              </div>
            </el-card>
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>问题描述</span>
                </div>
              </template>
              <div class="padding-xs">{{ dataInfo.matterContent }}</div>
              <div>
                <el-col :span="12" v-for="(item, index) in dataInfo.fileAList" :key="index">
                  <p class="text-cut flex align-center padding-mini fileitem">
                    <img :src="item.iconUrl" class="fileIcon" /><a @click="$site.view_file(item)">{{ item.name }}</a>
                  </p>
                </el-col>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>审核信息</span>
                </div>
              </template>
              <table class="table-detail">
                <tr>
                  <th>审核人</th>
                  <td>{{ dataInfo.verifyUserName }}</td>
                  <th>审核部门</th>
                  <td>{{ dataInfo.verifyDepartName }}</td>
                </tr>
                <tr>
                  <th>审核时间</th>
                  <td>{{ dataInfo.submitTime }}</td>
                  <th>审核结果</th>
                  <td class="text-bold">
                    <p v-if="dataInfo.matterStatus == 1">
                      已提交-
                      <span v-if="dataInfo.verifyHistoryIds" class="text-cyan">已转移</span>
                      <span v-else class="text-gray">未审核</span>
                    </p>
                    <p v-else-if="dataInfo.matterStatus == 9" class="text-red">无效</p>
                    <p v-else-if="dataInfo.matterStatus == 10" class="text-green">
                      已采纳-
                      <span v-if="dataInfo.effectStatus == 1" class="text-cyan">整改中</span>
                      <span v-else-if="dataInfo.effectStatus == 9" class="text-red">临时管控</span>
                      <span v-else-if="dataInfo.effectStatus == 2" class="text-green">整改完成</span>
                    </p>
                  </td>
                </tr>
                <tr v-if="dataInfo.unableDesc || dataInfo.transferDesc">
                  <th>审核说明</th>
                  <td colspan="3">{{ dataInfo.unableDesc || dataInfo.transferDesc }}</td>
                </tr>
              </table>
            </el-card>

            <el-card class="margin-xs" v-if="dataInfo.effectStatus == 1 || dataInfo.effectStatus == 2">
              <template #header>
                <div class="card-header">
                  <span>整改信息</span>
                </div>
              </template>
              <table class="table-detail">
                <tr>
                  <th>要求时间</th>
                  <td>{{ dataInfo.effectRequestDate }}</td>
                  <th>整改要求</th>
                  <td>{{ dataInfo.effectRequestDesc }}</td>
                </tr>
                <tr>
                  <th>整改人</th>
                  <td>{{ dataInfo.effectUserName }}</td>
                  <th>整改部门</th>
                  <td>{{ dataInfo.effectDepartName }}</td>
                </tr>
                <tr>
                  <th>整改时间</th>
                  <td>{{ dataInfo.effectTime }}</td>
                  <th>整改状态</th>
                  <td class="text-bold">
                    <span v-if="dataInfo.effectStatus == 0" class="text-gray">未整改</span>
                    <span v-else-if="dataInfo.effectStatus == 1" class="text-blue">整改中</span>
                    <span v-else-if="dataInfo.effectStatus == 2" class="text-green">整改完成</span>
                  </td>
                </tr>
                <tr>
                  <th>整改说明</th>
                  <td colspan="3">{{ dataInfo.effectContent }}</td>
                </tr>
                <tr>
                  <th>整改图片</th>
                  <td colspan="3">
                    <el-col v-for="(item, index) in dataInfo.effectImgList" :key="index" :span="6">
                      <el-card class="padding0 margin-mini" style="line-height:0px;">
                        <el-image style="width: 100%; height: 80px" :src="item" :preview-src-list="dataInfo.effectImgList"> </el-image>
                      </el-card>
                    </el-col>
                  </td>
                </tr>
                <tr v-if="dataInfo.fileCList && dataInfo.fileCList.length !== 0">
                  <th>相关文件</th>
                  <td colspan="3">
                    <el-col :span="12" v-for="(item, index) in dataInfo.fileCList" :key="index">
                      <p class="text-cut flex align-center padding-mini fileitem">
                        <img :src="item.iconUrl" class="fileIcon" /><a @click="$site.view_file(item)">{{ item.name }}</a>
                      </p>
                    </el-col>
                  </td>
                </tr>
              </table>
            </el-card>
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>验收评估</span>
                </div>
              </template>
              <el-form ref="dataInfo" :model="dataInfo" label-width="120px">
                <el-form-item label="验收结果" prop="checkState">
                  <el-radio-group v-model="dataInfo.checkState">
                    <el-radio-button :label="1">合格,隐患已整改根治</el-radio-button>
                    <el-radio-button :label="2">合格,隐患已整改,但会反复出现,已纳入定期查</el-radio-button>
                    <el-radio-button :label="9">不合格,退回</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="验收备注" prop="checkRemark">
                  <el-input type="textarea" v-model="dataInfo.checkRemark" autocomplete="off"></el-input>
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="record" label="操作日志" :style="panelStyle">
        <l-table :option="tableOption" :data="recordList">
          <template slot="itemState" slot-scope="scope">
            <span v-if="scope.row.dealState == 0" class="text-gray">未检测</span>
            <span v-else-if="scope.row.itemState == 1" class="text-green">完好</span>
            <span v-else-if="scope.row.itemState == 2" class="text-red">缺损</span>
          </template>
          <template slot="fee" slot-scope="scope">
            <span v-if="scope.row.dealState == 0" class="text-gray">---</span>
            <span v-else-if="scope.row.itemState == 1" class="text-green">---</span>
            <span v-else-if="scope.row.itemState == 2" class="text-red">{{ scope.row.fee }}</span>
          </template>
        </l-table>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button @click="save" type="primary">确认验收结果</el-button>
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      userInfo: null,
      imgList: null,
      preImgList: [],
      panelStyle: { height: document.documentElement.clientHeight * 0.9 - 200 + 'px', overflow: 'auto' },
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '记录人', prop: 'recordUser' },
          { label: '内容', prop: 'recordContent' },
          { label: '时间', prop: 'recordTime' }
        ]
      },
      recordList: null
    }
  },
  mounted() {
    this.getRecordList()
  },
  methods: {
    getRecordList() {
      this.$lgh.get('api/enterprise/safely/matter/recordlist', { matterId: this.dataInfo.matterId }).then(res => {
        this.recordList = res
      })
    },
    save() {
      this.$lgh.msgConfirm('确认验收评估?', () => {
        this.dataInfo.checkUserId = this.userInfo.userId
        this.dataInfo.checkUserName = this.userInfo.fullName
        this.$lgh.post('api/enterprise/safely/matter/check', this.dataInfo).then(res => {
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.fileIcon {
  height: 20px;
  width: 20px;
}

.fileitem {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: #409eff;
  }
}
</style>
