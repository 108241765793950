<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="检查类型">
          <l-select v-model="condition.matterType" :remote="$api.safelyMatterType"></l-select>
        </el-form-item>
        <el-form-item label="验收状态">
          <l-select v-model="condition.checkState" :data="$constant.safelyMatterCheckStateList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="提交部门">
          <l-select v-model="condition.createDepartId" :remote="$api.departList"></l-select>
        </el-form-item>
        <el-form-item label="审核部门">
          <l-select v-model="condition.verifyDepartId" :remote="$api.departList"></l-select>
        </el-form-item>
        <el-form-item label="整改部门">
          <l-select v-model="condition.effectDepartId" :remote="$api.departList"></l-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <l-select v-model="condition.dateType" :all="false" :data="$constant.safelyMatter_dateType"></l-select>
          </template>
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" size="small" width="300" placeholder="问题概要/上报人名称"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="matterType" slot-scope="scope">
          <el-button type="text" @click="$site.view_safely_matter_detail(scope.row.matterId)">{{ scope.row.matterType }}</el-button>
        </template>
        <template slot="matterStatusName" slot-scope="scope">
          <span v-if="scope.row.matterStatus == 0" class="text-grey">未提交</span>
          <span v-else-if="scope.row.matterStatus == 1" class="text-blue">已提交</span>
          <span v-else-if="scope.row.matterStatus == 10" class="text-green">已采纳</span>
          <span v-else-if="scope.row.matterStatus == 9" class="text-red">无效</span>
        </template>
        <template slot="effectStatusName" slot-scope="scope">
          <span v-if="scope.row.effectStatus == 0" class="text-grey">---</span>
          <span v-else-if="scope.row.effectStatus == 1" class="text-blue">整改中</span>
          <span v-else-if="scope.row.effectStatus == 2" class="text-green">已完成</span>
          <span v-else-if="scope.row.effectStatus == 9" class="text-red">临时管控</span>
        </template>
        <template slot="checkState" slot-scope="scope">
          <el-button type="text" v-if="scope.row.checkState == 0" @click="check(scope.row)"><span style="text-decoration: underline">点击验收</span></el-button>
          <span v-else-if="scope.row.checkState == 1" class="text-green">合格,已根治</span>
          <span v-else-if="scope.row.checkState == 2" class="text-green">合格,未根治</span>
          <span v-else-if="scope.row.checkState == 9" class="text-red">不合格,退回</span>
        </template>
        <template slot="operation" slot-scope="scope">
          <el-tooltip v-if="scope.row.matterStatus == 1" class="item" effect="dark" content="转移给其他人进行审核" placement="left">
            <el-button type="primary" size="mini" @click="transfer(scope.row)">审核转移</el-button>
          </el-tooltip>
          <el-tooltip v-if="scope.row.matterStatus == 10 && scope.row.effectStatus == 1" class="item" effect="dark" content="退回重新审核安排实施整改" placement="left">
            <el-button type="warning" size="mini" @click="reback(scope.row)">整改退回</el-button>
          </el-tooltip>
          <el-tooltip v-if="scope.row.effectStatus == 2 && scope.row.checkState == 0" class="item" effect="dark" content="验收评估" placement="left">
            <el-button type="warning" size="mini" @click="check(scope.row)">验收评估</el-button>
          </el-tooltip>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import viewDoCheck from './_doCheck'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'AdviceList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '问题描述', prop: 'matterContent', overflow: true },
          { label: '检查类型', slot: 'matterType' },
          { label: '上报人', prop: 'createUserName' },
          { label: '审核人', prop: 'verifyUserName' },
          { label: '整改人', prop: 'effectUserName' },
          { label: '上报时间', prop: 'submitTime', filter: (a, data) => this.$lgh.limitTo(a, 10) },
          { label: '审核日期', prop: 'verifyTime', filter: (a, data) => this.$lgh.limitTo(a, 10) },
          { label: '整改日期', prop: 'effectTime', filter: (a, data) => this.$lgh.limitTo(a, 10) },
          { label: '上报状态', slot: 'matterStatusName' },
          { label: '整改状态', slot: 'effectStatusName' },
          { label: '验收评估', slot: 'checkState' }
        ]
      },
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        departId: '',
        dateType: 'submitTime',
        startDate: '',
        endDate: '',
        keyword: '',
        effectStatus: '2',
        checkState: '',
        matterCategory: '',
        matterType: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/safely/matter/pagedlist', ['enterpriseCode', this.condition])
    },
    doExport() {
      const h = this.$createElement

      this.$lgh.get('api/enterprise/safely/matter/export', ['enterpriseCode', this.condition]).then((res) => {
        this.$lgh.msgSuccess(h('a', { attrs: { target: '_blank', href: res.link_url } }, '点击下载'))
      })
    },
    reback(item) {
      this.$lgh.msgConfirm('确认退回实施,重新审核吗?', () => {
        this.$lgh.post('api/enterprise/safely/matter/reback', item).then((res) => {
          this.getPageData()
        })
      })
    },
    transfer(item) {
      this.$site.sel_enterpriseEmployee(item.enterpriseCode, { userId: item.verifyUserId }).then((user) => {
        this.$lgh.msgConfirm('确认将此问题由[' + item.verifyUserName + ']转移给[' + user.name + ']进行审核吗？', () => {
          var data = Object.assign({}, item)
          data.transferDesc = '管理员[' + this.userInfo.fullName + ']后台操作建议审核转移'
          data.transferDepartId = user.departId
          data.transferDepartName = user.departName
          data.transferUserId = user.userId
          data.transferUserName = user.name
          this.$lgh.post('api/enterprise/safely/matter/verify?type=transfer', data).then((e) => {
            this.$lgh.notifySuccess('审核转移', '已转移给[' + data.transferUserName + ']')
            this.getPageData()
          })
        })
      })
    },
    async check(item) {
      await this.$lgh.openDialog({
        ins: viewDoCheck,
        title: '验收评估',
        data: {
          dataInfo: Object.assign({}, item),
          userInfo: this.userInfo
        }
      })
      this.$lgh.toastSuccess('操作完成')

      this.getPageData()
    }
  }
}
</script>
